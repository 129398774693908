/*! 2025-2-14 17:56:46 */
[data-v-597aa991]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.page-header[data-v-597aa991]{height:33px}[data-v-597aa991] .page-header .el-form-item{margin-bottom:0}.divider[data-v-597aa991]{height:1px;background-color:#f5f7fa;margin:9px 0 15px 0}.mb30[data-v-597aa991]{margin-bottom:30px}.mt30[data-v-597aa991]{margin-top:30px}.mb20[data-v-597aa991]{margin-bottom:20px}.mt20[data-v-597aa991]{margin-top:20px}.mb15[data-v-597aa991]{margin-bottom:15px}.mt15[data-v-597aa991]{margin-top:15px}.table-container[data-v-597aa991]{padding:20px 15px !important}.operation[data-v-597aa991]{display:flex;justify-content:space-evenly}.operation i[data-v-597aa991]{font-size:20px}.error-info[data-v-597aa991]{color:#ff4d4f;font-size:12px;line-height:1;position:relative;padding-top:5px}.pointer[data-v-597aa991]{cursor:pointer}[data-v-597aa991] .el-input-number--small{width:100px !important}[data-v-597aa991] .el-input-number--small .el-input__inner{padding:0 10px !important}[data-v-597aa991] .el-input-number--small .el-input-number__increase,[data-v-597aa991] .el-input-number--small .el-input-number__decrease{display:none !important}[data-v-597aa991] .el-input-number--small.error .el-input__inner{border-color:#ff4d4f}.lot-wrapper[data-v-597aa991]{display:flex;align-items:center}.lot-wrapper .el-input[data-v-597aa991]{flex:1}.lot-wrapper .el-icon-circle-plus-outline[data-v-597aa991]{margin-left:5px;cursor:pointer;font-size:20px;color:#2882ff}


.outbound .el-select-dropdown__item {
  width: 200px;
}
.el-tooltip__popper {
  max-width: 400px;
}


/*# sourceMappingURL=chunk-06df102e.8e5afb4d.css.map*/